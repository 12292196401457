/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { ReactComponent as VerticalElipsis } from'../../../assets/icons/elipsis-vertical.svg'
import { useState } from 'react';

const DropdownContainer = styled.div`
    position: relative;
`

export const DropdownMenuList = styled.ul`
    align-items: center;
    background-color: white;
    border-radius: 0.375rem;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    display: flex;
    flex-direction: column;
    list-style: none;
    margin-top: 4px;
    min-width: fit-content;
    max-width: 180px;
    padding-left: 0px;
    position: absolute;
    overflow: hidden;
    right: 0;
`

export const DropdownMenuListItem = styled.li`
    cursor: pointer;
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 0 8px;
    color: #010203;
    white-space: nowrap;
    padding: 6px;
    
    &:hover {
        background-color: rgb(243 244 246);    
    }
`

const DropdownToggleButton = styled.button`
    cursor: pointer;
    color: ${({ theme: { header } }) => header.textColor};
    display: flex;
    flex-direction: column;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 4px;
    border: none;
    background: transparent;
    
    &:hover {
        background-color: rgba(255, 255, 255, 0.25);
    }

    ${({ isActive }) =>
            isActive && `background-color: rgba(255, 255, 255, 0.25);`}
`

export const DropdownMenu = ({children}) => {
  const [isActive, setIsActive] = useState(false);

  return ( <DropdownContainer>
    <DropdownToggleButton
      isActive={isActive}
      onClick={() => setIsActive(!isActive)}
    >
      <VerticalElipsis style={{height: '24px'}} />
    </DropdownToggleButton>
    {isActive && <DropdownMenuList>{children}</DropdownMenuList>}
  </DropdownContainer>)
}