/** @jsx jsx */
import { Query, Subscription } from '@apollo/client/react/components';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { useContext, useRef, useState } from 'react';
import { HISTORY, SUBSCRIBE_RECEIPTS } from '../../actions/Messages';
import { ConfigContext } from '../../context/ConfigContext';
import Body from './Body';
import Footer from './Footer';
import Header from './Header';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 1;
  background-color: transparent;

  border-radius: 5px;
  width: 100%;
  height: 100%;

  overflow: hidden;
`;

const Chat = ({ client, history, chatUserId, config, isTyping }) => {
  return <Body isTyping={isTyping} config={config} history={history} />;
};

export default ({ isTyping, messagesFromSubscription }) => {
  const config = useContext(ConfigContext);
  const [hideHeader] = useState(config.header.hideHeader);
  const refetchTimes = useRef(0);

  return (
    <Container>
      {!hideHeader && <Header />}
      <Subscription subscription={SUBSCRIBE_RECEIPTS} variables={{ chatUserId: config.user.id }} />
      <Query
        fetchPolicy="network-only"
        nextFetchPolicy="cache-first"
        query={HISTORY}
        variables={{ channelId: config.channel.id, chatUserId: config.user.id }}
      >
        {({ data, loading, client, refetch }) => {
          if (loading) return null;
          if (localStorage.getItem('DEBUG')) {
            console.log(config);
          }
          let { history = [] } = data;
          // console.log({ history, messagesFromSubscription });
          history = [
            ...history,
            ...(messagesFromSubscription
              ? messagesFromSubscription.filter(message => !history.some(hMessage => hMessage.id === message.id))
              : []),
          ];
          if (!history.length && refetchTimes.current === 0) {
            // Try to fetch 1 more time in case bot message is late
            refetchTimes.current = 1;
            refetch();
          }
          if (!config.action.saveHistory) {
            history = history.filter(message => message.isActiveConversation);
          }
          return (
            <Chat config={config} client={client} history={history} chatUserId={config.user.id} isTyping={isTyping} />
          );
        }}
      </Query>
      <Footer />
    </Container>
  );
};
