/** @jsx jsx */
import { Mutation } from '@apollo/client/react/components';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { flatMap, sortBy } from 'lodash';
import { Component, Fragment, memo } from 'react';
import { findDOMNode } from 'react-dom';
import { ADD_MESSAGE } from '../../../actions/Messages';
import { ReactComponent as FileIcon } from '../../../assets/icons/file.svg';
import Balloon from '../../Balloon';
import Typing from '../../Balloon/TypingIndicator';
import ButtonTemplate from '../../ButtonTemplate';
import ChatCarousel from '../../Carousel';
import ImageHeight from '../../ImageHeight';
import ChatListTemplate from '../../ListTemplate';
import ChatQuickReplies from '../../QuickReplies';

const BodyUi = styled.div`
  height: 100%;
  overflow: auto;
  flex-grow: 1;
  padding: 0 1em 0 1rem;
  background: ${({ theme: { chat } }) => chat.backgroundColor || 'white'};
`;

const FileUI = styled.span`
  cursor: pointer;
  font-weight: 600;
  padding: 1em 0em 1em 0.5rem;
`;

const Anchor = styled.a`
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
  font-weight: 600;
`;

const NEW_LINE_REGEX = /(\r\n|\r|\n)/g;
const TELEPHONE_REGEX = /(\+|0\s{0,1}0)(\s*-*\/*\d{1}){10,11}/gim;
const MD_URL = /(\[link=[^\]]*\]\([^)]*\))/g;
const telephonePrefix = [
  '2',
  '3',
  '4',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '19',
  '50',
  '51',
  '52',
  '53',
  '54',
  '55',
  '56',
  '57',
  '58',
  '59',
  '60',
  '61',
  '63',
  '64',
  '65',
  '67',
  '68',
  '69',
  '71',
  '80',
  '81',
  '82',
  '83',
  '84',
  '86',
  '87',
  '89',
  '474',
  '473',
  '472',
  '471',
  '475',
  '477',
  '476',
  '478',
  '479',
  '470',
  '460',
  '4650',
  '4651',
  '4664',
  '4670',
  '4681',
  '4682',
  '4683',
  '483',
  '484',
  '485',
  '486',
  '487',
  '489',
  '456',
  '4618',
  '4655',
  '4666',
  '4667',
  '490',
  '491',
  '492',
  '493',
  '494',
  '495',
  '496',
  '497',
  '498',
  '499',
  '70',
  '77',
  '78',
  '800',
  '900',
  '902',
  '903',
  '904',
  '905',
  '909',
];

const retrieveTelephone = value => {
  const isTelephone = value.match(TELEPHONE_REGEX);
  if (!isTelephone) return;
  const parsed = isTelephone[0].replace(/(\s|\/|-)/g, '');
  const withoutLandCode =
    '' + parsed.replace(/^(\+32|0032|\+31|0031|\+91|0091|\+33|0032|\+49|0049|\+48|0048|\+352|00352)/, '');
  const prefix = telephonePrefix.find(prefix => {
    return withoutLandCode.startsWith(prefix);
  });
  if (!prefix) return;
  if (!(prefix && [8, 9].includes(withoutLandCode.length))) return;
  return parsed;
};

const EMAIL_REGEX =
  /([a-zA-Z0-9!#$%&'*+\\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)$/gim;

const URL_REGEX =
  /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9@.]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?/gim;

const replaceTextWithTelefoneUrl = (line = '') => {
  const urls = line.match(URL_REGEX);
  const emails = line.match(EMAIL_REGEX);
  const telephones = line.match(TELEPHONE_REGEX);
  const matches = sortBy(
    flatMap([...(urls || []).filter(u => !u.includes('@')), ...(emails || []), ...[telephones || []]]),
    item => line.indexOf(item)
  );
  if (!matches.length) return line;
  const text = matches.reduce((result, match, index) => {
    return result.replace(match, `###${index}`);
  }, line);
  const items = text.split(/###\d{1,3}/g);
  return items.map((part, index) => {
    const isLast = index + 1 === items.length;
    const item = matches[index - 1] || part;
    const isEmail = item.match(EMAIL_REGEX);
    const isURL = item.match(URL_REGEX);
    const isTelephone = retrieveTelephone(item.replace(/\s/g, ''));
    if (isEmail) {
      return (
        <Fragment>
          <Anchor key={index + '__mail'} href={`mailto:${item}`} target="_blank">
            {item}
          </Anchor>
          {part && <span key={index + '__text'}> {part}</span>}
          {!isLast && <span key={index + '__trail'}> </span>}
        </Fragment>
      );
    }
    if (isTelephone) {
      return (
        <Fragment>
          <Anchor key={index + '__phone'} href={`tel:${item.replace(/(\s|\/|-)/g, '')}`} target="_blank">
            {item}
          </Anchor>
          {part && <span key={index + '__text'}> {part}</span>}
          {!isLast && <span key={index + '__trail'}> </span>}
        </Fragment>
      );
    }
    if (isURL)
      return (
        <Fragment>
          <Anchor key={index + '__link'} href={`${!item.startsWith('http') ? 'http://' : ''}${item}`} target="_blank">
            {item}
          </Anchor>
          {part && <span key={index + '__text'}> {part}</span>}
          {!isLast && <span key={index + '__trail'}> </span>}
        </Fragment>
      );
    return (
      <Fragment>
        {part && <span key={index + '__text'}> {part}</span>}
        {!isLast && <span key={index + '__trail'}> </span>}
      </Fragment>
    );
  });
};

const TextWithURLS = memo(({ text = '' }) => {
  const replaceMDurls = text.split(NEW_LINE_REGEX).map((line, i) => {
    if (line.match(NEW_LINE_REGEX)) return <br key={`new__line__${i}`} />;
    if (line.match(MD_URL)) {
      const urls = line.split(MD_URL).map((s, index) => {
        if (s.match(MD_URL)) {
          const text = s.match(/\[([\s\S]*?)\]/)[1].replace('link=', '');
          const url = s.match(/\((.*?)\)/)[1];
          return (
            <Anchor
              style={{ textDecoration: 'underline' }}
              key={index + '__mdurl'}
              href={!(url.includes('http') || url.includes('www.')) ? `https://${url}` : url}
              target="_blank"
            >
              {text}
            </Anchor>
          );
        } else {
          return replaceTextWithTelefoneUrl(s);
        }
      });

      return urls;
    } else {
      return replaceTextWithTelefoneUrl(line);
    }
  });
  return flatMap(replaceMDurls);
});

export default class ChatBody extends Component {
  state = { isOpen: false, selectedImage: '' };
  scrollToBottom = () => {
    if (!this.chat) return;
    if (this.lastposition) {
      this.chat.scrollTop = this.chat.scrollHeight - this.lastposition;
      this.lastposition = undefined;
    } else {
      const node = findDOMNode(this.chat);
      node.scrollTop = node.scrollHeight;
    }
  };

  componentDidUpdate(prevProps, prevState) {
    this.scrollToBottom();
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  renderSwitch = (index, item = { content: {} }, mutation, config) => {
    switch (item.contentType) {
      case 'ContextMessage':
        return () => null;
      case 'TextMessage':
        return (
          <Balloon readReceipt={item.readReceipt} authorType={item.authorType} key={`Chat_${index}`}>
            <p style={{ margin: 0 }}>
              <TextWithURLS text={item.content.text || ''} />
            </p>
          </Balloon>
        );

      case 'ImageMessage':
        return (
          <Balloon readReceipt={item.readReceipt} authorType={item.authorType} key={`Chat_${index}`}>
            <ImageHeight
              onClick={() => this.setState({ selectedImage: item.content.url, isOpen: true })}
              maxHeight={250}
              src={item.content.url}
              alt={'ImageUrl'}
            />
          </Balloon>
        );
      case 'AudioMessage':
        return (
          <Balloon readReceipt={item.readReceipt} authorType={item.authorType} key={`Chat_${index}`}>
            <audio style={{ width: '150px', height: '25px' }} controls>
              <source src={item.content.url} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </Balloon>
        );
      case 'FileMessage':
        return (
          <Balloon readReceipt={item.readReceipt} authorType={item.authorType} key={`Chat_${index}`}>
            <FileIcon width="1em" />
            <FileUI onClick={() => window.open(item.content.url, '_blank')}>{createFileName(item.content.url)}</FileUI>
          </Balloon>
        );

      case 'VideoMessage':
        return (
          <Balloon readReceipt={item.readReceipt} authorType={item.authorType} key={`Chat_${index}`}>
            <video width="100%" height="auto" controls>
              <source src={item.content.url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Balloon>
        );
      case 'ButtonTemplate':
        return (
          <ButtonTemplate
            key={`ChatButtonTemplate_${index}`}
            payload={item.content}
            onButtonClick={mutation}
            config={config}
          />
        );
      case 'QuickReplies':
        return (
          <Fragment>
            <Balloon readReceipt={item.readReceipt} authorType={item.authorType} key={`Chat_${index}`}>
              <TextWithURLS text={item.content.text || ''} />
            </Balloon>
            <ChatQuickReplies config={config} onButtonClick={mutation} quickreply={item.content.quick_replies} />
          </Fragment>
        );
      case 'ListTemplate':
        return (
          <ChatListTemplate
            key={`ChatTemplate_${index}`}
            config={config}
            onButtonClick={mutation}
            elements={item.content.elements}
            buttons={item.content.buttons}
          />
        );
      case 'GenericTemplate':
        return <ChatCarousel config={config} onButtonClick={mutation} elements={item.content.elements} />;
      default:
        //Unknown, just show json stringified object
        return (
          <Balloon readReceipt={item.readReceipt} authorType={item.authorType} key={`Chat_${index}`}>
            {JSON.stringify(item.content)}
          </Balloon>
        );
    }
  };

  render() {
    const { history, config, isTyping } = this.props;
    return (
      <Mutation
        mutation={ADD_MESSAGE}
        variables={{
          chatUserId: config.user.id,
          channelId: config.channel.id,
          skillIds: config.skills,
          contextMessage: config.contextMessage,
          sessionId: config.sessionId
        }}
      >
        {(mutation, { loading }) => (
          <BodyUi
            ref={i => {
              this.chat = i;
            }}
          >
            {config.welcomeMessage && (
              <Balloon authorType={'channels'}>
                {config.welcomeMessage}
              </Balloon>
            )}
            {history.map((item, index) => this.renderSwitch(index, item, mutation, config))}
            {isTyping && (
              <Balloon authorType={'bot'}>
                <Typing />
              </Balloon>
            )}
          </BodyUi>
        )}
      </Mutation>
    );
  }
}

const createFileName = url => {
  try {
    if (!url) return 'Unknown Filename';
    if (url.includes('minio')) {
      return url.split('_')[1];
    } else {
      const part = url.split('.')[4];
      if (!part) return url;
      return `${url.split('.')[4].split('/')[1]}.${url.split('.')[4].split('/')[0]}`;
    }
  } catch (e) {
    return url;
  }
};
